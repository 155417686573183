// extracted by mini-css-extract-plugin
export var allInsightsContainer = "index-module--allInsightsContainer--jSKul";
export var canopyGreen = "index-module--canopyGreen--a8zcW";
export var carbonGrey = "index-module--carbonGrey--fcPnq";
export var dawnPink = "index-module--dawnPink--SxWyk";
export var hiveYellow = "index-module--hiveYellow--dlfLo";
export var horizonWhite = "index-module--horizonWhite--9fuvk";
export var insightContent = "index-module--insightContent--zdI6V";
export var insightTagsContainer = "index-module--insightTagsContainer--5L+0U";
export var latestInsightsBannerContainer = "index-module--latestInsightsBannerContainer--oPvOz";
export var latestInsightsBannerContent = "index-module--latestInsightsBannerContent--nvCX1";
export var latestInsightsContentContainer = "index-module--latestInsightsContentContainer--7UMdA";
export var oceanicBlue = "index-module--oceanicBlue--l9uUh";
export var page404Title = "index-module--page404-title--TTK16";
export var paginationContainer = "index-module--paginationContainer--CaIIm";