import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useMemo, useState, useRef } from "react";
import Moment from "react-moment";
import Layout from "../../../components/Layout";
import Pagination from "../../../components/Pagination/Pagination";
import StandardBanner from "../../../components/StandardHero/StandardHero";

import {
  latestInsightsBannerContainer,
  latestInsightsBannerContent,
  latestInsightsContentContainer,
  allInsightsContainer,
  insightContent,
  paginationContainer,
} from "./index.module.scss";
import SearchFilter from "../../../components/SearchFilter/SearchFilter";
const PageSize = 18;
const LatestInsights = ({
  data: {
    strapiLatestInsights,
    strapiLatestInsights: { subBanner, metaData },
    allStrapiInsights: { nodes: allInsights },
    allStrapiCaseStudies: { nodes: allCaseStudies },
  },
}) => {
  const bannerImage =
    strapiLatestInsights.heroBanner.image.localFile.childImageSharp
      .gatsbyImageData;
  const bannerTitle = "";
  const showBreadcrumb = strapiLatestInsights.heroBanner.showBreadcrumb;

  const scrollToRef = useRef();

  const breadcrumbs = [{ name: "LATEST INSIGHTS" }];
  const [selectValue, setSelectValue] = useState({
    value: "allCategories",
    label: "All Categories",
  });

  // select only articles with publish date is earlier than now
  const allArticles = [...allInsights, ...allCaseStudies]
    .sort((a, b) => new Date(b.publish_at) - new Date(a.publish_at))
    .filter(function (obj) {
      return new Date(obj.publish_at) < Date.now();
    });

  const filteredArticles =
    selectValue.value === "allCategories"
      ? allArticles
      : allArticles.filter((article) => {
          const result = article?.client_types
            ?.map((client_type) => client_type.Type)
            .filter((type) =>
              type.toLowerCase().includes(selectValue.value.toLowerCase())
            );

          return result.length === 0 ? false : true;
        });

  const [currentPage, setCurrentPage] = useState(1);

  const currentArticles = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return filteredArticles.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, filteredArticles]);

  const handleFilterChange = (value) => {
    setSelectValue(value);
    setCurrentPage(1);
  };

  const handleOnPageChange = (page) => {
    setCurrentPage(page);
    window.scrollTo({ behavior: "smooth", top: scrollToRef.current.offsetTop });
  };

  return (
    <Layout
      seo={{
        title: metaData?.pageTitle,
        description: metaData?.metadescription,
        image: strapiLatestInsights.heroBanner.image.localFile.publicURL,
        article: false,
      }}
    >
      <StandardBanner
        image={bannerImage}
        title={bannerTitle}
        showBreadcrumb={showBreadcrumb}
        breadcrumbs={breadcrumbs}
      />
      <section className={latestInsightsBannerContainer}>
        <img src={subBanner.image.localFile.publicURL} alt="logo" />
        <div className={latestInsightsBannerContent}>
          <h3>{subBanner.heading}</h3>
        </div>
      </section>
      <div ref={scrollToRef}>
        <SearchFilter
          data={allArticles}
          latestInsights={allInsights}
          setSelectValue={handleFilterChange}
          selectValue={selectValue}
          continueReading={null}
        />
      </div>

      <section className={latestInsightsContentContainer}>
        <div className={allInsightsContainer}>
          {currentArticles.map((article, index) => {
            const {
              authors,
              shortDesc,
              image,
              mainImage,
              media_type,
              slug,
              id,
              headingText,
              mediaType,
              publish_at,
            } = article;

            let mediaPath = "case-studies";
            if (mediaType) {
              switch (mediaType) {
                case "Guide":
                  mediaPath = "guides";
                  break;
                case "Report":
                  mediaPath = "reports";
                  break;
                case "Brochure":
                  mediaPath = "brochures";
                  break;
                default:
                  break;
              }
            }

            const insightLink = () => {
              if (mediaType) {
                return `/${mediaPath}/${slug}`;
              }
              if (media_type) {
                return media_type.tyoe.toLowerCase() === "video"
                  ? `/videos/${slug}`
                  : `/articles/${slug}`;
              }
            };

            return (
              <Link key={id} to={insightLink()} className={insightContent}>
                <GatsbyImage
                  image={
                    image?.image?.localFile?.childImageSharp?.gatsbyImageData ||
                    mainImage?.localFile?.childImageSharp?.gatsbyImageData
                  }
                  alt={image?.image?.alternativeText}
                />
                <h4>{shortDesc || headingText}</h4>
                {authors ? (
                  authors?.length === 0 ? (
                    <p className="ui">
                      <Moment format="D MMM YYYY">{publish_at}</Moment>
                    </p>
                  ) : (
                    <p className="ui">
                      {authors?.map(({ name }, index) => {
                        return (
                          <span>
                            {index === 0 && (
                              <Moment format="D MMM YYYY">{publish_at}</Moment>
                            )}
                            , {name}
                          </span>
                        );
                      })}
                    </p>
                  )
                ) : (
                  <p className="ui">
                    <Moment format="D MMM YYYY">{publish_at}</Moment>
                  </p>
                )}
                {/* Temporarily commented out.  to be added later
                <div className={insightTagsContainer}>
                  <p className="ui">retirement</p>
                  <p className="ui">dws</p>
                </div> */}
              </Link>
            );
          })}
        </div>
        <div className={paginationContainer}>
          <Pagination
            currentPage={currentPage}
            totalCount={filteredArticles.length}
            pageSize={PageSize}
            onPageChange={(page) => handleOnPageChange(page)}
          />
        </div>
      </section>
    </Layout>
  );
};

export default LatestInsights;

export const query = graphql`
  {
    strapiLatestInsights {
      heroBanner {
        title
        showBreadcrumb
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
        }
      }
      insights {
        id
      }
      subBanner {
        heading
        image {
          localFile {
            publicURL
          }
          alternativeText
        }
      }
      metaData {
        metadescription
        pageTitle
      }
    }
    allStrapiInsights(
      sort: { fields: publish_at, order: DESC }
      filter: {
        media_type: { tyoe: { eq: "Article" } }
        publish_at: { ne: null }
      }
    ) {
      nodes {
        authors {
          name
          published_at
        }
        published_at
        publish_at
        shortDesc
        id
        slug
        subtitle
        media_type {
          tyoe
        }
        client_types {
          Type
          id
        }
        image {
          image {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  height: 420
                  width: 645
                  placeholder: DOMINANT_COLOR
                )
              }
            }
          }
        }
      }
    }
    allStrapiCaseStudies(
      sort: { order: DESC, fields: publish_at }
      filter: { mediaType: { nin: ["CaseStudy"] } }
    ) {
      nodes {
        id
        published_at
        publish_at
        mediaType
        headingText
        headerCopy
        slug
        client_types {
          Type
          id
        }
        mainImage {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(
                height: 420
                width: 645
                placeholder: DOMINANT_COLOR
              )
            }
          }
        }
      }
    }
  }
`;
