import React, { useMemo, useRef, useState } from "react";
import Modal from "react-responsive-modal";
import Select from "react-select";
import searchIcon from "../../images/icons/searchIcon.svg";
import Input from "../Input/Input";
import {
  searchContainer,
  search,
  filter,
  customModal,
  modalInput,
  modelInputContainer,
  resultsContainer,
  active,
} from "./SearchFilter.module.scss";

import closeIcon from "../../images/CloseIcon.svg";
import Divider from "../Divider/Divider";
import { Link } from "gatsby";
import ContinueReading from "../ContinueReading/ContinueReading";

const SearchFilter = (props) => {
  const [data] = useState(props.data);
  const [searchValue, setSearchValue] = useState("");
  const [open, setOpen] = useState(false);
  const modalRef = useRef(null);
  const { latestInsights = [] } = props;

  const clientTypes = useMemo(
    () => data.reduce((prev, curr) => [...prev, ...curr.client_types], []),
    [data]
  );

  const uniqueClientTypes = useMemo(
    () => [
      ...new Map(clientTypes.map((item) => [item["Type"], item])).values(),
    ],
    [clientTypes]
  );

  const filterOptions = useMemo(
    () => [
      { value: "allCategories", label: "All categories" },
      ...uniqueClientTypes.map((client) => ({
        value: client.Type,
        label: client.Type,
      })),
    ],
    [uniqueClientTypes]
  );

  const sortedFilterOptions = filterOptions.sort((a, b) =>
    a.value.localeCompare(b.value)
  );

  const filteredData = useMemo(
    () =>
      data?.filter((item) => {
        return (
          item?.headingText?.toLowerCase().indexOf(searchValue.toLowerCase()) >
            -1 ||
          item?.shortDesc?.toLowerCase().search(searchValue.toLowerCase()) != -1
        );
      }),
    [data, searchValue]
  );

  return (
    <React.Fragment>
      <section className={`${searchContainer}`}>
        <div className={search} onClick={() => setOpen(true)}>
          <img src={searchIcon} alt="" />
          <p className="ui">search</p>
        </div>
        <div className={filter}>
          <Select
            className="ui"
            onChange={(selection) => props.setSelectValue(selection)}
            options={filterOptions}
            styles={{
              option: (provided) => ({
                ...provided,
                color: "black",
              }),
              placeholder: (provided) => ({
                ...provided,
                color: "#2d2d2d",
              }),
              singleValue: (provided) => ({
                ...provided,
              }),
              dropdownIndicator: (provided) => ({
                ...provided,
                color: "#2d2d2d",
                opacity: 1,
                "&:hover": {
                  color: "#2d2d2d",
                  opacity: 0.4,
                },
              }),

              control: (provided, state) => ({
                ...provided,
                backgroundColor: "transparent",
                border: 0,
                boxShadow: "none",
                height: "50px",
                borderRadius: "0",
                minWidth: 160,
                color: "#2d2d2d",
                cursor: "pointer",
              }),
            }}
            placeholder="All Categories"
            components={{ IndicatorSeparator: () => null }}
          />
          <ul role="list">
            {sortedFilterOptions.map((option) => (
              <li
                key={option.value}
                className={
                  props.selectValue.value === option.value ? active : null
                }
                onClick={() => props.setSelectValue(option)}
              >
                <p>{option.label}</p>
              </li>
            ))}
          </ul>
        </div>
      </section>

      <Modal
        open={open}
        blockScroll={true}
        showCloseIcon={false}
        onClose={() => {
          setSearchValue("");
          setOpen(false);
        }}
        ref={modalRef}
        classNames={{
          modal: customModal,
          overlay: "customOverlay",
        }}
      >
        <div className={modelInputContainer}>
          <Input
            className={`${modalInput}`}
            value={searchValue}
            placeholder="SEARCH AMX"
            onChange={(event) => setSearchValue(event.target.value)}
          />
          <img
            src={closeIcon}
            alt="close-icon"
            onClick={() => setOpen(false)}
          />
        </div>
        {searchValue && (
          <React.Fragment>
            <Divider />
            <div className={resultsContainer}>
              <p className="ui">Search results:</p>

              {filteredData.map((result, index) => {
                let mediaPath = result?.mediaType
                  ? `${result?.mediaType?.toLowerCase()}s`
                  : result.media_type.tyoe.toLowerCase() === "video"
                  ? "videos"
                  : "articles";
                return (
                  <React.Fragment>
                    <Link to={`/${mediaPath}/${result.slug}`} key={result.slug}>
                      {result.shortDesc || result.headingText}
                    </Link>
                  </React.Fragment>
                );
              })}
            </div>
          </React.Fragment>
        )}
        <Divider />
        <ContinueReading
          allArticles={latestInsights}
          continueReadingArticles={{ insights: latestInsights }}
          currentArticleId={data[0].id}
          inView={true}
          showNextTwo={true}
        />
      </Modal>
    </React.Fragment>
  );
};

export default SearchFilter;
